@value avatar as orAvatar from "Profile.module.css";
@value avImage as orImg from "Profile.module.css";
@value label from "../../Components/Checkbox.module.css";

.twoInputs {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

@media only screen and (max-width: 800px) {
    .twoInputs, .inliner, .bottomButtons {
        flex-direction: column !important; 
    }
    .inliner {
        align-items: flex-start;
    }
    .twoInputs {
        margin-bottom: 1rem !important;
    }
    .bottomButtons {
        flex-direction: column;
        width: 100% !important;
    }
}
@media only screen and (min-width: 801px) {
    .bottomButtons > * {
        width: fit-content;    
        padding-inline: 5rem;
    }
}

.bottomButtons {
    display: flex;
    justify-content: space-between;
    gap: 1rem;
}

.avatar {
    border-radius: 100%;
    overflow: hidden;
    filter: none;
    position: relative;
    max-height: 15rem;
    max-width: 15rem;
}

.avImage {
    composes: orImg;
}

.inputHolder {
    display: flex;
    justify-content: center; 
    width: 100%;
}

.coverer {
    position: absolute;
    background-color: rgba(0, 0, 0, .8);
    width: 100%;
    height: 100%;
    transition-property: opacity;
    transition-duration: .1s;
    transition-timing-function: cubic-bezier();
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
}

.covererIcon {
    width: 50%;
    height: 50%;
    color: rgba(255, 255, 255, .9);
}

.invisible {
    display: none;
}


.hidden {
    opacity: 0;
}

.hidden:hover {
    opacity: 1;
}

.inliner {
    display: flex;
    flex-direction: row;
    gap: 2rem;
}

.switchContainer {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1rem;
}

.themeSwitch {
    display: flex;
    border: 3px solid var(--add1-500);
    box-shadow: 0px 2px 0px rgba(250, 71, 83, 0.6);
    height: 1.3rem;
    min-width: 3.5rem;
    width: 3.5rem;
    padding: .5rem;
    border-radius: 2.2rem;
    color: var(--main-400);
    align-items: center;
    cursor: pointer;
}

.ballWrapper {
    height: 1.5rem;
    width: 1.5rem;
    position: relative;
    transition: transform .1s ease-in-out;
}

.ballWrapper > * {
    position: absolute;
    height: inherit;
    width: inherit;
    opacity: 0;
    transition: opacity .1s ease-out;
}

.current {
    opacity: 1;
}

.left {
    transform: translateX(0);
}

.right {
    transform: translateX(2rem);
}


.example {
    width: 100%;
}

.addForm button {
    width: fit-content;
}

.normalParagraph {
    padding-top: 2rem;
    padding-bottom: .5rem;
}

.topParagraph {
    padding-top: 0;
    padding-bottom: .5rem;
}

.addForm > div:last-of-type > * {
    margin: 1rem 0;
    padding: .25rem;
} 