.flexRow {
    display: flex;
}

.wrapper {
    padding: 3em 6em;
    width: 100%;
    box-sizing: border-box;
    overflow-y: auto;
    height: 100vh;
}

@media screen and (max-width: 600px) {
    .wrapper {
        padding: 1rem 1rem;
        padding-bottom: 3rem;
    } 
}