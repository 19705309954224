.searchCont {
    box-sizing: border-box;
    position: fixed;
    max-height: 100vh;

    align-items: center;
    padding: 4rem 2rem;
    background-color: var(--bg-clr);

    border-width: 0px 3px 3px 0px;
    border-style: solid;
    border-color: var(--add1-500);
    border-radius: 0px 0px 8px 0px;

    order: 4;
    flex-grow: 0;
    z-index: 11;
    top: 0;

    transition-property: top, opacity;
    transition-duration: .3s;
    transition-timing-function: cubic-bezier(0.68, -0.55, 0.265, 1.55);
    
    overflow-y: auto;
}

@media screen and (max-width: 900px) {
    .searchCont {
        left: 0 !important;
        width: 100%;
        border-width: 0px 0px 3px 0px;
        border-radius: 0;
    }
}

.inputsContainer {
    display: flex;
    flex-direction: row;
    gap: 1rem;
}

.inputsContainer > button {
    width: auto;
    height: inherit;
}

.disabled {
    opacity: 0;
}

.enabled {
    opacity: 1;
    top: 0;
}
