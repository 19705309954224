@media screen and (max-width: 900px) {
  .managementIcons {
    display: none !important;
  }
  .narrowContainer {
    width: 100% !important;
  }
  .buttonFlex > button {
      max-width: none !important;
  }
  .h1 {
    text-align: center !important;
  }
}

.h1 {
  margin: 1rem 0;
}

.narrowContainer {
  width: 45%;
}

.wideContainer {
  width: 100%;
}

.menu {
  font-size: 32px;
  display: flex;
  align-items: center;
  color: var(--main-300);
  gap: 16px;
  width: fit-content;
}

.menu > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu svg {
  font-size: 36px;
  cursor: pointer;
}

.menu svg:first-of-type {
  font-size: 44px;
}

.menu > button {
  font-size: 20px;
  padding: 8px 16px;
  border-width: 2px;
  border-radius: 6px;
}

.active {
  color: var(--add1-500);
}

.posts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.post {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
}

.post h2 {
  color: var(--main-400);
  position: relative;
  padding: 0;
  font-family: "Quicksand", sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
  font-weight: normal;
  font-size: 2rem;
}

.topData > a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--main-400);
}

.topData {
  flex-wrap: wrap;
}

.topData,
.bottomData,
.topData p {
  display: flex;
  font-size: 24px;
  color: var(--main-400);
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.bottomDataDisabled {
  display: flex;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topData {
  gap: 0.5rem;
}

.topData > div {
  font-size: 1.5rem;
  display: flex;
  align-items: center;
  gap: 8px;
}

.topData > div:last-of-type > svg {
  cursor: pointer;
}

.bottomData,
.bottomDataDisabled {
  gap: 1rem;
}

.bottomData > svg {
  cursor: pointer;
  width: 2rem;
  color: var(--add1-500);
}

.bottomDataDisabled > svg {
  cursor: not-allowed;
  width: 2rem;
  color: var(--main-300);
}

.bottomDataDisabled > button {
  cursor: not-allowed;
}

.content {
  font-size: 2rem;
  color: var(--main-400);
}

.post > img {
  width: fit-content;
}

@keyframes pulse-black {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}

.buttonFlex {
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-wrap: wrap;
  border-top: 1px solid var(--main-100);
  padding-top: 1rem;
  gap: 1rem;
}

.buttonFlex > button {
  flex: 1;
  min-width: fit-content;
  overflow: hidden;
}
