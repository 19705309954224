.mainCont {
   min-height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2rem;
    align-items: center;
}

.mainCont h2 {
    font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 2rem;
}

.mainCont a {
    width: fit-content;
}

.mainCont svg {
    color: var(--add1-500);
    min-width: 250px;
    min-height: 250px;
}