.mainDesc {
  font-size: 1.5rem;
  margin: 1rem;
  line-height: 150%;
}

@media only screen and (max-width: 1300px) {
  .lastItem {
    margin-bottom: 10rem;
  }
}

@media only screen and (max-width: 900px) {
  .related {
    flex-direction: column;
    align-items: center;
    margin-top: 1rem;
  }
}

.offerLi > span {
  font-size: 1.25rem;
  margin: 1rem 0;
}

.offerLi {
  margin: 0.5rem 0;
}

.h2, .h1 {
  margin: 2rem 0;
}

.h1:first-of-type {
  margin-bottom: 0;
}

.related {
  display: flex;
  font-size: 1.25rem;
  gap: 1rem;
  flex-wrap: wrap;
  color: var(--add1-500);
  margin-bottom: 2rem;
}

.related > a {
  color: var(--add1-500);
}