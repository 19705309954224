.main {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
}

.main > p:first-of-type {
  font-size: 2rem;
  color: var(--add1-500);
}

.main > p {
  text-align: center;
  font-size: 1.25rem;
}

.main button, .main input {
  width: fit-content;
}
