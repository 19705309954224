.button {
    width: 100%;
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: 8px 16px;
    font-size: 24px;
    border-radius: 10px;
    font-family: 'Quicksand', sans-serif;
    box-sizing: border-box;
    cursor: pointer;
    justify-content: center;
    color: var(--light);
    transition: all .3s;
}

.fit {
    width: fit-content;
}

.default {
    border: 2px solid var(--add1-500);
    background-color: var(--add1-500);
    box-shadow: 0 4px 0 0 var(--add1-400);
}

.default:hover {
    transform: translateY(3px);
    box-shadow: none;
}

.gray {
    border: 2px solid var(--light-200);
    background-color: var(--add2-200);
    color: var(--main-500);
    box-shadow: 0 2px 0 0 var(--black-100);
}

.gray:hover {
    transform: translateY(3px);
    box-shadow: none;
}

.grayDisabled {
    border: 2px solid var(--light-200);
    background-color: var(--add2-200);
    color: var(--main-300);
    box-shadow: 0 2px 0 0 var(--black-100);
}

.grayDisabled:hover {
    transform: none;
}

.alternate {
    border: 2px solid var(--add1-500);
    background-color: var(--bg-clr);
    color: var(--add1-500);
    box-shadow: 0 2px 0 0 var(--add1-400);
}

.alternate:hover {
    transform: translateY(3px);
    box-shadow: none;
}

.default:disabled {
    background-color: var(--main-200);
    color: var(--main-400);
    cursor: not-allowed;
    border-color: var(--main-200);
    box-shadow: 0 1px 0 var(--black-100);
}

.button:active,
.button:focus {
    outline: none;
}

.facebook {
    margin-top: .5em;
    border-color: var(--facebook-blue);
    color: var(--facebook-blue);
    box-shadow: 0 1px 0 var(--facebook-blue);
}
