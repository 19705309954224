.linkList {
    list-style: none;
    padding: 0;
}

.link {
    display: flex;
    align-items: center;
    padding-block: .7rem;
    text-decoration: none;
    font-size: 28px;
}

.clickable {
    color: var(--add2-500);
}

.clickable:hover {
    cursor: pointer !important;
    user-select: none;
}

.clickable label {
    cursor: pointer !important;
}

.linkList label {
    cursor: pointer !important;
}

.selected {
    color: var(--add1-500) !important;
}

@media screen and (min-width: 901px) {
    .mobile {
        display: none;
    }
    .notmobile {
        display: block !important;
    }
}

.notmobile {
    display: none;
}

.tooltip {
  position: relative;
}

.tooltip:hover::after {
  content: attr(tooltip-dsc);
  font-size: 1rem;
  text-align: center;
  position: absolute;
  width: 100%;
  bottom: 20%;
  left: -5px;
  min-height: 2rem;
  background-color: var(--add2-500);
  color: var(--bg-clr);
  padding: .25rem;
  border-radius: .25rem;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}
