.avatar {
    --size: 20rem;
    min-width: var(--size);
    min-height: var(--size);
    max-width: var(--size);
    max-height: var(--size);
    overflow: hidden;
    border-radius: 100%;
    filter: drop-shadow(8px 4px 0px var(--add1-500)) drop-shadow(-8px -4px 0px var(--add2-500));
    box-sizing: border-box;
}

@media screen and (max-width: 900px) {
    .postWrapper {
        width: 100% !important;
    }
    .managementContainer {
        padding: 0 !important;
    }
    .avatar {
        margin-top: 2rem;
        --size: 15rem;
        min-width: var(--size);
        min-height: var(--size);
        max-width: var(--size);
        max-height: var(--size);
    }
    .personContainer {
        flex-direction: column;
        align-items: center !important;
        position: relative;
    } 
    .buttonsArea {
        flex-direction: column !important;
        gap: 1rem;
    }
    .creations {
        flex-direction: column;
        width: 100%;
    }
    .creations button {
        width: 100% !important;
    }
    .buttonsArea > p {
        font-size: 1.5rem;
    }
    .profileContent > p {
        text-align: center;
    }
    .detailsContainer p:first-of-type {
        text-align: center;
    }
}

.avImage {
    width: 100%;
    height: 100%;
}

.personContainer {
    display: flex;
    width: 100%;
    margin-bottom: 2rem;
}

.managementContainer {
    display: flex;
    justify-content: space-between;
    flex-direction: column;
    padding-inline: 5rem;
    width: 100%;
}

.detailsContainer {
    display: flex;
    flex-direction: column;
    row-gap: 1rem;
    padding: 1rem 0;
    color: var(--main-300);
    font-size: 1.5rem;
}

.detailsContainer h2 {
    font-size: 2.5rem;
}

.detailsContainer p:first-of-type {
    font-size: 1.75rem;
    color: var(--main-400);
}

.detailsContainer {
    line-break: anywhere;
}

.ovColor {
    color: inherit;
}

.buttonsArea {
    flex-direction: row; 
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 1rem;
    column-gap: 2rem;
    border-top: 1px solid var(--main-100);
}

.buttonsArea > button {
    flex: 1;
    max-width: 40%;
}

.buttonsArea > p {
    text-align: center;
}

.activeFollowing {
    cursor: pointer;
}

.followerFlex {
    display: flex;
    gap: 2rem;
}

.creations {
    width: 100%;
    display: flex;
    margin-top: 2rem;
    padding: 1rem 0;
    box-sizing: border-box;
    gap: 1rem;
    position: relative;
}

.creations::before {
    content: '';
    position: absolute;
    width: 100%;
    height: .5px;
    background-color: var(--main-100);
    top: 0;
    left: 0;
}

.report {
    cursor: pointer;
    color: var(--main-400);
}

.creations button {
    width: 50%;
}

.profileContent {
    width: 100%;
    display: flex;
    justify-content: space-around;
    align-items: center;
    flex-wrap: wrap;
    gap: 1rem;
    box-sizing: border-box;
}

.profileContent > p {
    font-size: 2rem;
    color: var(--add1-500);
    width: 100%;
}

.postWrapper {
    width: 49%;
}

.post{
    display: flex;
    flex-direction: column;
    gap: 32px;
}

.topData, .bottomData {
    display: flex;
    font-size: 24px;
    color: var(--main-400);
    display: flex;
    justify-content: space-between;
    align-items: center;
    flex-wrap: wrap;
}

.topData > div,
.bottomData > div {
    display: flex;
    align-items: center;
    gap: 8px;
}

.topData > div:last-of-type > svg {
    cursor: pointer;
}

.bottomData > div > p {
    transition: color .1s linear;
}

.bottomData > div {
    cursor: pointer;
}

.bottomData > div:first-of-type > svg {
    animation: pulse-black .2s linear;
}

.content {
    font-size: 2rem;
    color: var(--main-400);
}

@keyframes pulse-black {
    0% {
        transform: scale(1);
    }

    70% {
        transform: scale(1.3);
    }

    100% {
        transform: scale(1);
    }
}

.textCenter {
    text-align: center;
}