.question {
  color: var(--add1-500);
  font-size: 2rem;
  font-family: "Quicksand";
  padding-bottom: 1rem;
  border-bottom: 0.0625rem solid var(--main-100);
}

.answer {
  font-size: 1.5rem;
  padding-top: 1rem;
}

.questionCont {
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
  gap: 1rem;
  flex-direction: row-reverse;
  justify-content: flex-end;
  border: 1px solid var(--main-100);
  padding: 2rem;
  border-radius: 8px;
  box-shadow: 0px 1px 0px var(--main-100);
  box-sizing: border-box;
}

.icon {
  color: var(--add1-500);
  width: 2rem;
  height: 2rem;
}

.hierarchyIcon {
  width: 1.2rem;
  height: 1.2rem;
  transition: fill 0.2s;
}

.hierarchyIcon:hover {
  fill: var(--add1-500);
}

.hierarchyMan {
  display: flex;
  justify-content: center;
  flex-direction: column;
  text-align: center;
  gap: 0.2rem;
}

.iconCont:hover {
  cursor: pointer;
}

.buttonCont {
  display: flex;
  align-items: center;
  gap: 1rem;
}

.buttonCont > button {
  max-width: fit-content;
  max-height: fit-content;
}

.buttonDesc {
  margin-bottom: 0.5rem;
}

@media screen and (max-width: 900px) {
  .buttonDesc {
    margin-bottom: 0.5rem;
    text-align: center;
  }
  .questionCont {
    justify-content: center;
    flex-direction: column-reverse;
  }
  .question,
  .answer {
    text-align: center;
  }
  .menu {
    width: 100% !important;
    justify-content: center;
  }
  .managementIcons {
    flex-direction: column;
  }
  .buttonCont {
    justify-content: center;
  }
}

.faqIcons {
  display: flex;
  gap: 0.5rem;
}

.active {
  color: var(--add1-500);
}

.menu {
  font-size: 32px;
  display: flex;
  align-items: center;
  color: var(--main-300);
  gap: 16px;
  width: fit-content;
  margin-bottom: 1rem;
}

.menu > div {
  display: flex;
  align-items: center;
  gap: 1.5rem;
  margin-top: 1rem;
}

.menu svg {
  font-size: 2rem;
  cursor: pointer;
}

.questionsList {
  list-style: none;
  padding-left: 0;
  margin-block: 2rem;
}

.questionsList .questionElem::marker {
  color: var(--add1-500);
  font-size: 1.5rem;
  text-align: left;
}

.menu > button {
  font-size: 20px;
  padding: 8px 16px;
  border-width: 2px;
  border-radius: 6px;
}

.managementIcons div {
  display: flex;
  align-items: center;
  cursor: pointer;
  gap: 0.5rem;
}
