.addForm {
    flex-direction: column;
    gap: 1rem;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
}

.addForm > textarea {
    min-height: 10rem;
    display: flex;
    align-items: center;
    font-size: 1.75rem;
    border-radius: 10px;
    font-family: 'Quicksand', sans-serif;
    box-sizing: border-box;
    appearance: none;
    border: 4px solid var(--add1-500);
    filter: drop-shadow(0px 2px 0px var(--add1-500));
    color: var(--main-500);
    position: relative;
    resize: vertical;
}

.addForm > textarea::placeholder {
    color: var(--main-400);
}

.postOptions {
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 1rem;
    position: relative;
}

.h1 {
    text-align: center;
    margin-bottom: 1rem;
}

.postOptions input[type="date"],
.postOptions input[type="time"] {
    flex: 1;
    min-width: 40%;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 1.75rem;
    border-radius: 10px;
    font-family: 'Quicksand', sans-serif;
    box-sizing: border-box;
    appearance: none;
    border: 4px solid var(--add1-500);
    box-shadow: 0px 2px 0px var(--add1-500);
    color: var(--main-400);
    position: relative;
}

@media screen and (max-width: 900px) {
    .inputFlex {
        flex-direction: column;
    }
}

.inputFlex {
    display: flex;
    gap: 1rem;
    flex-wrap: wrap;
}

.postOptions input[type="date"]:focus,
.postOptions input[type="time"]:focus,
.postOptions input[type="date"]:active,
.postOptions input[type="time"]:active,
.addForm > textarea:focus,
.addForm > textarea:active {
    outline: none;
}