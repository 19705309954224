.input {
    width: 100%;
    display: flex;
    align-items: center;
    padding: 8px 16px;
    font-size: 1.75rem;
    border-radius: 10px;
    font-family: 'Quicksand', sans-serif;
    box-sizing: border-box;
    background-color: var(--bg-clr);
    color: var(--main-500);
}

.default {
    border: 4px solid var(--add1-500);
    filter: drop-shadow(0px 2px 0px var(--add1-500));
}

.alternate {
    border: 4px solid var(--add2-500);
    filter: drop-shadow(0px 2px 0px var(--add2-500));
}

.input:active, .input:focus {
    outline: none;
}