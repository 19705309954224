.main {
  display: flex;
  min-height: 100vh;
  overflow: hidden;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  gap: 1.5rem;
  text-align: center;
}

.main button, .main input {
  width: fit-content;
}

.main h1 {
    font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
}

.main p {
    font-size: 1.25rem;
}