.main {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    gap: 1rem;
}

.main input {
    width: 32px !important;
    height: 32px !important;
    appearance: none;
    background-color: var(--add2-400);
    border-radius: 100%;
}

.main input:checked {
    background-color: var(--add1-500);
}

.main div {
    display: flex;
    align-items: center;
    gap: 1rem;
    white-space: normal;
    line-break: anywhere;
    width: 100%;
}

.main div > * {
    cursor: pointer;
}

.main label {
    font-size: 1rem;
    max-width: 80%;
}