@media screen and (max-width: 900px) {
  .managementIcons {
    display: none !important;
  }
  .narrowContainer {
    width: 100% !important;
  }
  .menu {
    width: 100% !important;
    justify-content: center;
  }
  .topData {
    justify-content: space-around !important;
  }
  .caroseul {
    width: 80% !important;
  }
  .loadMoreButton > button {
    width: 100%;
  }
}

.narrowContainer {
  width: 45%;
}

.wideContainer {
  width: 100%;
}

.menu {
  font-size: 32px;
  display: flex;
  align-items: center;
  color: var(--main-300);
  gap: 16px;
  width: fit-content;
}

.menu > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.menu svg {
  font-size: 36px;
  cursor: pointer;
}

.menu svg:first-of-type {
  font-size: 44px;
}

.menu > button {
  font-size: 20px;
  padding: 8px 16px;
  border-width: 2px;
  border-radius: 6px;
}

.active {
  color: var(--add1-500);
}

.posts {
  display: flex;
  justify-content: space-around;
  align-items: center;
  flex-wrap: wrap;
}

.post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 32px;
}

.topData,
.bottomData {
  display: flex;
  font-size: 24px;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.topData {
  flex-wrap: wrap;
}

.topData > div:first-of-type > a {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.5rem;
  color: var(--main-400);
}

.topData > div {
  display: flex;
  align-items: center;
  gap: 8px;
}

.topData,
.topData p,
.bottomData > div {
  display: flex;
  align-items: center;
  gap: 8px;
  color: var(--main-400);
}

.report {
  cursor: pointer;
  color: var(--main-400);
}

.bottomData > div > p {
  transition: color 0.1s linear;
  color: var(--main-400);
}

.bottomData > div {
  cursor: pointer;
}

.bottomData > div:first-of-type > svg {
  animation: pulse-black 0.2s linear;
}

.content {
  font-size: 2rem;
  color: var(--main-400);
}

.loadMoreButton {
  width: 100%;
  display: flex;
  justify-content: center;
}

.loadMoreButton > button {
  width: 30%;
  margin-bottom: 3rem;
}

@keyframes pulse-black {
  0% {
    transform: scale(1);
  }

  70% {
    transform: scale(1.3);
  }

  100% {
    transform: scale(1);
  }
}
