.wrapper {
    padding: 2rem;
    border-radius: .5rem;
    border: 0.0625rem solid var(--main-100);
    margin-block: 2rem;
    box-shadow: 0px 1px 0px var(--main-100);
    box-sizing: border-box;
}

.wrapper > h2 {
    font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
    font-size: 28px;
    font-weight: 600;
    margin-bottom: 16px;
}

.wrapper > p {
    font-size: 1.5rem;
    color: var(--main-400);
    font-weight: 500;
}