.walkCont {
    width: 100%;
    min-height: calc(100vh - 4rem);
    display: flex;
    justify-content: center;
} 
.iframe {
    width: 100%;
    height: 100%;
}
.spinner {
    max-width: 100%;
    position: fixed;
}