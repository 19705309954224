@import url('https://fonts.googleapis.com/css2?family=Quicksand&display=swap');
@import url('https://fonts.googleapis.com/css?family=Zen+Dots');

:root {
  --light: #fdfdfd;
  --dark: #272727;
  --shadow: #17181C;
  --bg-clr: var(--light);
  --main-500: var(--black-500);
  --main-400: var(--black-400);
  --main-300: var(--black-300);
  --main-200: var(--black-200);
  --main-100: var(--black-100);
  --black-500: #17181C;
  --black-400: #17181C80;
  --black-300: #17181C60;
  --black-200: #17181C40;
  --black-100: #17181C20;
  --light-500: #DBDBDB;
  --light-400: #DBDBDB80;
  --light-300: #DBDBDB60;
  --light-200: #DBDBDB40;
  --light-100: #DBDBDB20;
  --add1-500: #FA4753;
  --add1-400: #FA475380;
  --add1-300: #FA475360;
  --add1-200: #FA475340;
  --add1-100: #FA475320;
  --add2-500: #9BA5C9;
  --add2-400: #9BA5C980;
  --add2-300: #9BA5C960;
  --add2-200: #9BA5C940;
  --add2-100: #9BA5C920;
  --facebook-blue: #4267B2;
}

body {
  margin: 0;
  font-family: 'Quicksand', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: var(--bg-clr);
  color: var(--main-500);
}

* {
  transition: background-color 200ms linear;
}

p {
  color: var(--main-500)
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
  monospace;
}

::placeholder {
  color: var(--main-300);
  opacity: 1;
}

:-ms-input-placeholder {
  color: var(--main-300);
}

::-ms-input-placeholder {
  color: var(--main-300);
}

input[type="date"]::-webkit-calendar-picker-indicator,
input[type="time"]::-webkit-calendar-picker-indicator {
  filter: invert(0.7) saturate(5) hue-rotate(175deg);
}

p {
  margin-block-start: 0;
  margin-block-end: 0;
}

h1, h2 {
  color: var(--add1-500);
  font-family: 'Zen Dots', sans-serif;
  margin-block-start: 0;
  margin-block-end: 0;
}

a {
  text-decoration: none;
}

input:disabled {
  background-color: var(--add1-300);
}

.notification-container {
  box-sizing: border-box;
  position: fixed;
  top: 0;
  right: 2rem;
  z-index: 999999;
  width: 320px;
  padding: 0 15px;
  max-height: calc(100% - 30px);
  overflow-x: hidden;
  overflow-y: auto;
}

.notification {
  box-sizing: border-box;
  padding: 15px 15px 15px 58px;
  border-radius: 2px;
  color: #fff;
  background-color: #ccc;
  box-shadow: 0 0 12px var(--black-100);
  cursor: pointer;
  font-size: 1em;
  line-height: 1.2em;
  position: relative;
  opacity: 0.9;
  margin-top: 15px;
}

.notification .title {
  font-size: 1em;
  line-height: 1.2em;
  font-weight: bold;
  margin: 0 0 5px 0;
}

.notification:hover,
.notification:focus {
  opacity: 1;
}

.notification-enter {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
}

.notification-enter.notification-enter-active {
  visibility: visible;
  transform: translate3d(0, 0, 0);
  transition: all 0.4s;
}

.notification-exit {
  visibility: visible;
  transform: translate3d(0, 0, 0);
}

.notification-exit.notification-exit-active {
  visibility: hidden;
  transform: translate3d(100%, 0, 0);
  transition: all 0.4s;
}

.notification:before {
  position: absolute;
  top: 50%;
  left: 15px;
  margin-top: -14px;
  display: block;
  font-family: 'Notification', sans-serif;
  width: 28px;
  height: 28px;
  font-size: 28px;
  text-align: center;
  line-height: 28px;
}

.notification-success {
  background-color: #51a351;
}

.notification-success:before {
  color: var(--bg-clr);
  content: url('data: image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="%23fdfdfd" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M10.97 4.97a.235.235 0 0 0-.02.022L7.477 9.417 5.384 7.323a.75.75 0 0 0-1.06 1.06L6.97 11.03a.75.75 0 0 0 1.079-.02l3.992-4.99a.75.75 0 0 0-1.071-1.05z" /></svg>');
}

.notification-warning {
  background-color: #f89406;
}

.notification-warning:before {
  content: url('data: image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="16" height="16" fill="%23fdfdfd" class="bi bi-exclamation-triangle-fill" viewBox="0 0 16 16"><path d="M8.982 1.566a1.13 1.13 0 0 0-1.96 0L.165 13.233c-.457.778.091 1.767.98 1.767h13.713c.889 0 1.438-.99.98-1.767L8.982 1.566zM8 5c.535 0 .954.462.9.995l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 5.995A.905.905 0 0 1 8 5zm.002 6a1 1 0 1 1 0 2 1 1 0 0 1 0-2z" /></svg>');
}

.notification-error {
  background-color: #bd362f;
}

.notification-error:before {
  content: url('data: image/svg+xml,<svg xmlns="http://www.w3.org/2000/svg" width="32" height="32" fill="%23fdfdfd" class="bi bi-exclamation-circle" viewBox="0 0 16 16"><path d="M8 15A7 7 0 1 1 8 1a7 7 0 0 1 0 14zm0 1A8 8 0 1 0 8 0a8 8 0 0 0 0 16z" /><path d="M7.002 11a1 1 0 1 1 2 0 1 1 0 0 1-2 0zM7.1 4.995a.905.905 0 1 1 1.8 0l-.35 3.507a.552.552 0 0 1-1.1 0L7.1 4.995z" /></svg>');
}
@media screen and (max-width: 900px) {
  h1, h2 {
    text-align: center;
  }
}