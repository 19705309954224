.navbar {
  background-color: var(--bg-clr);
  display: flex;
  justify-content: space-between;
  padding: 2em;
  flex-direction: column;
  height: 100vh;
  box-sizing: border-box;
  font-size: 1.5em;
  overflow-y: hidden;
  border-right: 1px solid var(--main-100);
  min-width: fit-content;
  z-index: 11;
}

.disable {
  visibility: none;
}

@media screen and (max-width: 900px) {
  .navbar {
    max-height: 100vh;
    height: 4rem;
    position: fixed;
    display: flex;
    z-index: 100;
    width: 100vw;
    padding: 0;
    height: fit-content !important;
    bottom: 0;
    flex-direction: column-reverse;
    border-top: 1px solid var(--main-100);
    overflow-y: auto;
    z-index: 9;
    -webkit-box-shadow: 0px -32px 48px -16px var(--black-200);
    -moz-box-shadow: 0px -32px 48px -16px var(--black-200);
    box-shadow: 0px -32px 48px -16px var(--black-200);
  }
  .mainLogo {
    display: none;
  }
  .mainIcons {
    flex-direction: row;
    display: flex;
    justify-content: space-around;
    width: 100%;
  }
  .mainIcons label {
    display: none !important;
  }
  .mainIcons svg {
    width: 2.5rem;
    height: 2.5rem;
  }
  .mainIcons {
    margin: 0 !important;
  }
  .mainIcons li {
    width: 100%;
  }
  .mainIcons a,
  .mainIcons div {
    display: flex;
    justify-content: center;
  }
  .mainIcons span {
    margin: 0;
  }
  .show {
    padding-inline: 1rem !important;
    font-size: 1.5rem;
    color: var(--add2-500);
    text-align: center;
  }
  .hidden {
    display: none;
    font-size: 1.5rem;
    color: var(--add2-500);
    text-align: center;
  }
}

.hidden, .show {
  font-size: 1.25rem;
  color: var(--add2-500);
  text-align: center;
}

ul {
  margin: 0;
}

.mainLogo {
  font-size: 0.7em;
}

.hide {
  width: 100vw;
  height: 100vh;
  position: absolute;
  left: 0;
  top: 0;
  z-index: 10;
  background-color: var(--black-400);
}
