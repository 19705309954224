.checkbox {
    --size: 2.5rem;
    min-width: var(--size);
    min-height: var(--size);
    width: var(--size);
    height: var(--size);
    border-radius: 10px;
    border: 3px solid var(--add1-500);
    box-shadow: 0px 2px 0px rgba(250, 71, 83, 0.6);
    appearance: none;
    cursor: pointer;
    transition: 100ms all linear;
}

.radio {
    width: 2.5rem;
    height: 2.5rem;
    border-radius: 100%;
    border: 3px solid var(--add1-500);
    box-shadow: 0px 2px 0px rgba(250, 71, 83, 0.6);
    appearance: none;
    cursor: pointer;
    transition: 100ms all linear;
}

.checkboxFlex {
    display: flex;
    align-items: center;
    gap: 1rem;
    padding: .5rem 0;
}

.label {
    font-size: 32px;
    font-family: 'Quicksand', sans-serif;
    color: var(--main-300);
    cursor: pointer;
}

.checkbox:checked, 
.radio:checked {
    background-color: var(--add1-300);
}

.checkbox:active,
.checkbox:focus, 
.radio:active,
.radio:focus {
    outline: none;
}