@value avImage as orImg from "../../Sites/User/Profile.module.css";

@media screen and (max-width: 900px) {
  .modal {
    width: 90vw !important;
    max-height: 90vh !important;
    left: 5vw !important;
  }
}

.background {
  position: fixed;
  z-index: 110;
  background-color: var(--black-400);
  width: 100vw;
  height: 100vh;
  top: 0;
  left: 0;
}

.modal {
  position: fixed;
  width: 50vw;
  border-radius: 4px;
  top: 32px;
  left: 25%;
  z-index: 150;
  padding: 32px;
  box-sizing: border-box;
  background-color: var(--bg-clr);
  animation: 0.2s enteringSlide;
  overflow-y: auto;
  max-height: 80vh;
}

@keyframes enteringSlide {
  from {
    transform: translateY(-25px);
  }
  to {
    transform: translateY(0px);
  }
}

.modal > p:nth-of-type(2) {
  font-size: 2rem;
  color: var(--add1-500);
  margin-bottom: 2rem;
}

.warning {
  color: var(--main-400);
  font-size: 1.5rem;
  margin-bottom: 1rem;
}

.closeModal {
  color: var(--main-300);
  font-size: 2rem;
  position: absolute;
  top: 1rem;
  right: 1rem;
  cursor: pointer;
}

.textarea {
  width: 100%;
  display: flex;
  min-height: 250px;
  margin-bottom: 32px;
}

.textarea:active,
.textarea:focus {
  outline: none;
}

.modal ul {
  list-style-type: none;
  list-style-position: inside;
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 1rem;
  padding-inline-start: 0;
}

.modal li > a {
  font-size: 2rem;
  color: var(--main-400);
  display: flex;
  gap: 2rem;
  align-items: center;
  padding-bottom: 0.5rem;
}

.modal li:not(:last-of-type) > a {
  border-bottom: 1px solid var(--main-100);
}

.avatar {
  width: 2rem;
  height: 2rem;
  overflow: hidden;
  border-radius: 100%;
}

.avImage {
  composes: orImg;
}
