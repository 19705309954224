@media screen and (max-width: 900px) {
    .img {
        display: none !important;
    }
    .formSide {
        width: 100% !important;
    }
    .loginForm {
        width: 100% !important;
        border: none !important;
        box-shadow: none !important;
    }
}

.img {
    width: 50%;
    background-image: url('./Graphics/bglogin.png');
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
}

.formSide {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 50%;
}

.loginFlex {
    display: flex;
    min-height: 100vh;
    overflow: hidden;
}

.loginForm {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem .5rem;
    gap: 28px;
    width: 60%;
    height: auto;
    border: 1px solid var(--main-100);
    -webkit-box-shadow: 0px 1px 96px -8px var(--black-100);
    -moz-box-shadow: 0px 1px 96px -8px var(--black-100);
    box-shadow: 0px 1px 96px -8px var(--black-100);
    border-radius: 8px;
}

.loginForm > img {
    width: 400px;
}

.loginForm p {
    font-size: 1.75rem;
    color: var(--main-400);
}

.loginForm p:not(:first-of-type) {
    cursor: pointer;
}

.loginForm > a {
    font-size: 1.5rem;
    text-decoration: none;
    color: var(--main-400);
}

.form {
    width: 80%;
}

.form > input:not(.form > input:nth-of-type(2)), .form > div {
    margin-bottom: 16px;
}

.form > p {
    font-size: 24px;
    margin-top: 8px;
    color: var(--main-400);
    cursor: pointer;
}