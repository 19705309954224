.h1 {
  text-align: center;
  margin: 1rem 0;
}

.zsemDesc > p {
  margin: 2rem 0;
}

.zsemDesc > button {
  width: fit-content !important;
}

.p {
  margin-top: 2rem;
  text-align: center;
  font-size: 1.25rem;
}

.caroseul {
  margin: 4rem 0;
  width: 40%;
  position: relative;
  filter: drop-shadow(0px 0px 32px var(--main-300));
  border: 4px solid var(--add1-500);
  background-color: var(--add1-100);
  border-radius: 8px;
  overflow: hidden;
}

.sliderWrapper {
  margin: 1rem;
  position: relative;
  overflow: hidden;
}

.slidesContainer {
  height: 100%;
  width: 100%;
  display: flex;
  overflow: hidden;
  scroll-behavior: smooth;
  align-items: center;
  list-style: none;
  margin: 0;
  padding: 0;
}

.slideArrow {
  position: absolute;
  display: flex;
  top: 0;
  bottom: 0;
  margin: auto;
  height: 4rem;
  background-color: #c70303;
  border: none;
  width: 2rem;
  font-size: 3rem;
  padding: 0;
  cursor: pointer;
  opacity: 0.5;
  transition: opacity 100ms;
  color: rgb(255, 255, 255);
}

.slideArrow:hover,
.slideArrow:focus {
  opacity: 1;
}

.slideArrowPrev {
  left: 0;
  padding-left: 0.25rem;
  border-radius: 0 2rem 2rem 0;
}

.slideArrowNext {
  right: 0;
  padding-left: 0.75rem;
  border-radius: 2rem 0 0 2rem;
}

.slide {
  width: 100%;
  height: 100%;
  flex: 1 0 100%;
}

.authors a {
  color: var(--main-400);
  display: flex;
  align-items: center;
}

.authors {
  margin-top: 1.5rem;
}

.authors li {
  margin: 0.5rem 0;
  width: fit-content;
}

.authors a:hover,
.authors li::marker {
  color: var(--add1-500);
}

.authors a:last-of-type {
  gap: 1rem;
  width: fit-content;
}

@media screen and (max-width: 900px) {
  .centerOnPhone {
    text-align: center;
  }
  .zsemDesc > p {
    text-align: justify;
    word-spacing: -2px;
  }
  .caroseul {
    width: 80% !important;
  }
}

.offerLink {
  color: var(--add1-500);
}

.descList {
  width: 100%;
  margin: 1rem;
  font-size: 1.5rem;
  color: var(--main-400);
}

.descList li {
  margin: 1rem 0.5rem;
}